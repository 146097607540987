
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 6px;
}
.select2-container--default .select2-selection--single {
  height: 47px;
}
.assets-modal .select2-container {
  width: 91% !important;
}
.assets-modal .modal-body{
  height: 294px;
}
.modal-footer {
  justify-content: normal;
  display: initial;
}
.table {
  border: 1px solid #2a2c40;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.upload-files .file-upload-default {
  position: absolute;
  opacity: 0;
  width: 100% !important;
  height: 47px;
  cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.upload-files .fileupload-input {
  position: absolute;
  height: 45px;
  width: 100%;
  opacity: 0;
}
/* check box */

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border-color: #a9a7a7;
  border-style: solid;
  border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid;
  border-color: #3da5f4;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.asset-url button {
  background: #22243b;
  color: #c3bdbd;
  border: #ccc;
}
.form-control:disabled {
  background-color: #2b2e4c;
}

.card-body {
  min-height: calc(100vh - 124px);
}
#snackbar {
  border-radius: 2px;
  padding: 8px 12px;
  height: 37px;
}
.switch-height{
  min-height: 24px;
}
.progress-md{
  height: 8px!important;
}