.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 6px;
}
.select2-container--default .select2-selection--single {
  height: 47px;
}
.assets-modal .select2-container {
  width: 91% !important;
}
.modal-footer {
  justify-content: normal;
  display: initial;
}
/* dashboard */
.select-container {
  position: relative;
  width: 190px;
  float:right;
 }
 .select-container:focus,
 .css-10nd86i:focus {
  box-shadow: 0 0 0 0 transparent!important;
  outline: none!important;
 }
 .select-container .css-vj8t7z,
.select-container .css-2o5izw,
.select-container .css-162g8z5{
  border: 1px solid #b5b5b5!important;
  background-color:transparent;
 
  /* height: 47px; */
  padding-left: 5px;
}
.dashboard .css-2o5izw  {
  box-shadow: none!important;
}
.css-1492t68{
  top: 45%;
}
.select-container .css-mtrsvw,
.css-162g825{
  background-color: transparent;
}
.css-vj8t7z:hover,
.css-vj8t7z:focus,
.css-1sontr1:hover,
.css-1sontr1:focus {
  border-color: #b5b5b5;
  background-color: #2b2e4c;
}
.css-15k3avv{
  color: #000;
}
 select {
   border: 1px solid #adadad;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance:none;
  width: 100%;
  color:#aaa7a7;
  outline:none;
  padding-left: 10px;
  transition: 0.2s;
  height:34px;
  background: transparent;
 }
 select:-moz-focusring {
  color: transparent;
 }
 
 select::-ms-expand {
  display: none;
 }
 
 .select-arrow {
  color: #7d7d7b;
  right:0px;
  top: 10px;
  width:30px;
  position:absolute;
  display:block;
  z-index: 10;
  margin: 0 0 0 0;
  pointer-events:none;
 }
 
 .table {
  border:none !important;
 }
 select option {
  background: #131633;
  color:#aaa7a7;
 }

.dashboard-select,
.dashboard-select:focus{
 background: transparent;
 border: 1px solid #f8f9fa !important;
 max-width:220px;
}
/* select{
  -webkit-appearance: none;
  appearance: none;
} */
/* .selsct-btn-block select{
  width: 268px;
    line-height: 1;
    border: 0;
   
    background: url(../../../public/images/down-arrow.png) no-repeat right #131633;
  
    z-index: 100;
} */
.w-60{
  width: 60%
}
.select-container .css-vj8t7z, .select-container .css-2o5izw, .select-container .css-162g8z5, .select-container .css-bg1rzq-control {
  border: 1px solid #686868!important;
}
.css-1ep9fjw {
  color: hsl(0, 0%, 55%)!important;
}
.css-d8oujb  {
  background-color: hsl(0, 0%, 55%)!important;
}
/* dashboard */
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.upload-files .file-upload-default {
  position: absolute;
  opacity: 0;
  width: 100% !important;
  height: 47px;
  cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.upload-files .fileupload-input {
  position: absolute;
  height: 45px;
  width: 100%;
  opacity: 0;
}
/* check box */

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border-color: #a9a7a7;
  border-style: solid;
  border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid;
  border-color: #3da5f4;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.asset-url button {
  background: #22243b;
  color: #c3bdbd;
  border: #ccc;
}
.form-control:disabled {
  background-color: #2b2e4c;
}
.card-body {
  min-height: calc(100vh - 124px);
}
#snackbar {
  border-radius: 2px;
  padding: 8px 12px;
  height: 37px;
}


.flex_left {
  justify-content: flex-start;
  display: flex;
}
.no-cards {
  font-size: 19px!important;
  margin-top: 8px;
}
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.highcharts-credits {
  /* display: none; */
}
rect.highcharts-background,
.export-to-pdf rect.highcharts-background {
  /* fill: rgb(19, 21, 51) !important; */
}

/* rect.highcharts-background
{  fill: rgb(19, 21, 51) !important;
} */
.highcharts-text-outline {
/* stroke: none !important; */
}

.highcharts-data-label tspan{
  fill: #ffffff !important; 
  font-size: 12px;
  font-weight: 100 !important;
}
.lds-dual-ring::before {
  margin-top: 20px!important;


}

/* .highcharts-text-outline */

/* 
 .dashboard .css-mtrsvw ,  .dashboard .css-vj8t7z {
  background-color:transparent!important;
  border: 1px solid #686868!important;
  box-shadow: 0 0 0 0 transparent!important;
  
}
.dashboard .css-mtrsvw:focus ,  
.dashboard .css-vj8t7z:focus ,
.dashboard .css-mtrsvw:active ,  
.dashboard .css-vj8t7z:active {
  box-shadow: 0 0 0 0 transparent!important;
}
.dashboard .css-bl6clz:focus,
.dashboard .css-bl6clz:active {
  border-color: transparent!important;
  box-shadow: none!important;
  outline: 0!important;
  -webkit-box-shadow: none!important;
          box-shadow: none!important;
          box-shadow: 0 0 0 0 transparent!important;

}  */

/* ask nikil */
.dash-card{
  min-height: 500px
}

.no-card-p{
  margin-bottom: 10%
}
.export-to-pdf{
  position: absolute;
  left: -9999px;
  background: #131633;
}
.justify_content {
  display: flex;
  justify-content: flex-end;
}
.dwnld_pdf {
  margin-right: 15px;
}
.jjj {
  border: none!important;
  /* background-color: red!important; */
  border: 1px solid #fff;
}
.css-vj8t7z {
  border-style: none!important;
}
.fl-left {
  float: left;
}
.table-wrp {
  display: inline-flex;
}

  /* .fiftyp {
    width: 50%;
  } */
 .tb {
   width: 100%;
 }
 .block-left {

   width:100%;
 }
 .botom-cards {
   width: 35%;
 }
 .card-wrapper {
   width:80%;
 }
 .label-lh{
  margin-bottom: 0;
  line-height: 2.6em;
 }