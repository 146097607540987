.portfolio-grid .gallery-card-height figure.effect-text-in:hover img {
  -webkit-transform: none;
  transform: none;
}

.portfolio-grid .gallery-card-height figure.effect-text-in:hover h4 {
  -webkit-transform: none;
  transform: none;
}
.app-icon {
  height: 152px;
  position: relative;
  background: #2b2e4c;
}
.app-icon-upload {
  position: absolute;
  bottom: 2px;
  right: 2px;
  height: 35px;
  width: 35px;
  background: #22243c;
  padding: 9px;
  border-radius: 50%;
}
.icon-upload {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
.icon-upload input {
  height: 179px;
  opacity: 0;
  cursor: pointer;
}
.upload-ph-text {
  position: absolute;
  top: 53px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.app-icon-upload input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
}
.app-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.portfolio-grid .gallery-card-height figure.effect-text-in h5 {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 10px;
  margin-bottom: 0;
  width: 100%;
}
.gallery-card-height .effect-text-in {
  max-height: 405px;
  height: 279px;
}
.gallery-card-height figure.effect-text-in img {
  height: 100%;
  width: auto;
  object-fit: contain;
  margin: auto;
}
.portfolio-grid .gallery-card-height {
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}
.portfolio-grid figure img{
  opacity: 1;
}
.portfolio-grid .gallery-card-height {
  flex: 0 0 50%;
  max-width: 50%;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 6px;
}
.card-modal .modal-content {
  height: 639px;
}
.card-modal .css-10nd86i,
.card-modal .css-1sontr1,
.file-upload-default,
.apps-modal .css-1sontr1,
.apps-modal .css-10nd86i,
.css-ssi0ig-container,
.apps-modal .css-1pcexqc-container,
.card-modal .css-1pcexqc-container

{
  width: 80% !important;
}
.card-modal .css-142w6gm,
.apps-modal .css-142w6gm{
color:#e9e6e6;
font-size: 0.875rem;
}
.card-modal .css-vj8t7z,
.card-modal .css-2o5izw,
.card-modal .css-162g8z5,
.apps-modal .css-vj8t7z,
.apps-modal .css-162g8z5,
.apps-modal .css-2o5izw,
.css-14ojxsb-control,
.css-bg1rzq-control,
.css-1szy77t-control{
  border: 1px solid #2a2c40 !important;
  background-color: #2b2e4c !important;
  height: 47px !important;
  padding-left: 5px;
}
.css-vj8t7z:hover,
.css-vj8t7z:focus,
.css-1sontr1:hover,
.css-1sontr1:focus {
  border-color: #80bdff;
  background-color: #2b2e4c;
}
.apps-modal .css-15k3avv,
.css-kj6f9i-menu{
  color: #000 !important;
}
.modal .css-1uq0kb5:hover,
.modal .css-1uq0kb5:focus{
  color: hsl(235, 27%, 42%);
}
.card-modal .css-1ep9fjw:hover,
.apps-modal .css-1ep9fjw:hover {
  color: hsl(235, 27%, 42%);
}
.card-modal .css-1ep9fjw,
.apps-modal .css-1ep9fjw,
.css-dvua67-singleValue {
  color: hsl(235, 31%, 52%);
}
.css-dvua67-singleValue {
  color: rgba(233, 230, 230,0.6) !important;
}
.card-modal .css-d8oujb,
.card-modal .css-ln5n5c,
.apps-modal .css-d8oujb,
.apps-modal .css-ln5n5c,
.card-modal .css-bgvzuu-indicatorSeparator,
.apps-modal .css-bgvzuu-indicatorSeparator  {
  background-color: hsl(235, 27%, 18%);
}
.modal-footer {
  justify-content: normal;
  display: initial;
}
.portfolio-grid figure figcaption {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgb(19, 22, 51)),
    to(rgba(0, 0, 0, 0)),
    color-stop(0.5, #22284029)
  );
}
.modal-md {
  max-width: 40%;
  min-width: 600px;
}
.custom-control-label::before {
  border: solid #405189;
  border-width: 2px;
  background: transparent;
}
.custom-control-input:active ~ .custom-control-label::after {
  border: 2px solid #405189;
  background: transparent;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: transparent;
  box-shadow: none;
}
/* radio button */
.form-control[readonly] {
  background-color: #2b2e4c;
}
.css-xp4uvy {
  color: hsl(0, 2%, 79%) !important;
  font-size: 0.875rem;
}
.css-1sontr1 {
  font-size: 0.875rem;
  font-weight: 100;
}
/* radio button */

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: transparent !important;
  border-radius: 100%;
  border: 2px solid #5f65ab !important;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 12px;
  margin-right: 1em;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #2b2e4c;
  box-shadow: inset 0 0 0 2px #3197ee;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #2b2e4c;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  /* box-shadow: inset 0 0 0 4px #f4f4f4; */
  border-color: #b4b4b4;
  background: #b4b4b4;
  cursor: default;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
/* empty card message */
.no-card-ph {
  height: 65vh;
  left: 0;
  right: 0;
  margin: auto;
}
p.no-cards {
  font-size: 1.5em;
  color: #656a8f;
}
.ph-img {
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
}

.refresh-btn-block .btn {
  float: right;
}
/* empty card message */
.mdi.mdi-refresh {
  font-size: 1.8em;
}
.icon-padding {
  padding: 5px;
}

.d-block-show {
  display: block;
}

.d-block-hide {
  display: none;
}

.hide-down-arrow .css-1wy0on6 {
  display: none;
}
.error.small {
  height: 15px;
}
.card-url .input-group > .form-control{
  flex: inherit;
}
.traget-addon .input-group-text{
  background: #22243c33;
  color: #a3a4a5;
}

/* navbar */
.nav-opacity{
  opacity: 0.5;
}
#card_width{
  max-width:80px;
  -moz-appearance: textfield;
}
.failed-status-position{
  position: absolute;
  right: 0;
  top: 22px;
  border-radius: 3px 0 0 3px;
}
.card-active-label{
  position: absolute;
  left: 0;
  top: 0;
  -webkit-box-shadow:4px 2px 34px 6px rgba(0,0,0,0.75); 
    -moz-box-shadow:4px 2px 34px 6px rgba(0,0,0,0.75);
    box-shadow:4px 2px 34px 6px rgba(0,0,0,0.75);
    
}
.card-opacity{
  opacity: 0.3;
}
@media (min-width: 530px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 620px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
.gallery-card-height .effect-text-in {
    max-height: 405px;
    height: 234px;
}
}
@media (min-width: 768px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .gallery-card-height .effect-text-in {
    max-height: 405px;
    height: 279px;
}
}
@media (min-width: 960px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 992px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
@media (min-width: 1160px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 1400px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1745px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 16.66666666667%;
    max-width: 16.66666666667%;
  }
}
@media (min-width: 1890px) {
  .portfolio-grid .gallery-card-height {
    flex: 0 0 14.28571428571429%;
    max-width: 14.28571428571429%;
  }
}