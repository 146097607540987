.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 6px;
}
.select2-container--default .select2-selection--single {
  height: 47px;
}
.assets-modal .select2-container {
  width: 91% !important;
}
.modal-footer {
  justify-content: normal;
  display: initial;
}
.table {
  border: 1px solid #2a2c40;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.upload-files .file-upload-default {
  position: absolute;
  opacity: 0;
  width: 100% !important;
  height: 47px;
  cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.upload-files .fileupload-input {
  position: absolute;
  height: 45px;
  width: 100%;
  opacity: 0;
}
/* check box */

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border-color: #5f65ab;
  border-style: solid;
  border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid;
  border-color: #3da5f4;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.asset-url button {
  background: #22243b;
  color: #c3bdbd;
  border: #ccc;
}
.form-control:disabled {
  background-color: #2b2e4c;
}
.card-body {
  min-height: calc(100vh - 124px);
}
#snackbar {
  border-radius: 2px;
  padding: 8px 12px;
  height: 37px;
}
.user-icon{
  height: 140px;
  max-width: 140px;
  position: relative;
  background: #2b2e4c;
}
.user-icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-icon .upload-ph-text {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.user-icon .upload-ph-text p{
  padding-top: 50px;
}
.user-icon-upload {
  position: absolute;
  bottom: 2px;
  right: 2px;
  height: 95px;
  width: 95px;
  background: #22243c;
  padding: 9px;
  border-radius: 50%;
}
/* checkbox */

/* The customcheck */
.customcheck {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size:15px;
  padding-top: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  /* left: 0; */
  height: 25px;
  width: 25px;
  /* background-color: #eee; */
  border-radius: 5px;
}
/* When the checkbox is checked, add a blue background */
.customcheck input:checked ~ .checkmark {
  background-color: #3da5f4;
  border-radius: 5px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customcheck input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.customcheck .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.user-profile select{
  -webkit-appearance: none;
} 
.disable-height{
  height:28px;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-color: #2a2c40 #2a2c40 #39415a;
}
/* subscription plan */

.pricing-card-body {
  padding: 40px 30px 33px 30px;
}