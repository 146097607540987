
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: 6px;
}
.select2-container--default .select2-selection--single {
  height: 47px;
}
.assets-modal .select2-container {
  width: 91% !important;
}
.assets-modal .modal-body{
  height: 294px;
}
.modal-footer {
  justify-content: normal;
  display: initial;
}
.table {
  border: 1px solid #2a2c40;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.upload-files .file-upload-default {
  position: absolute;
  opacity: 0;
  width: 100% !important;
  height: 47px;
  cursor: pointer;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper:before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: solid #405189;
  border-width: 2px;
  -webkit-transition: all;
  -moz-transition: all;
  -ms-transition: all;
  -o-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}
.upload-files .fileupload-input {
  position: absolute;
  height: 45px;
  width: 100%;
  opacity: 0;
}
/* check box */

/* The check */
.check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  padding-right: 15px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border-color: #a9a7a7;
  border-style: solid;
  border-width: 2px;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  border-color: #3da5f4;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid;
  border-color: #3da5f4;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.asset-url button {
  background: #22243b;
  color: #c3bdbd;
  border: #ccc;
}
.form-control:disabled {
  background-color: #2b2e4c;
}

.card-body {
  min-height: calc(100vh - 124px);
}
#snackbar {
  border-radius: 2px;
  padding: 8px 12px;
  height: 37px;
}
.switch-height{
  min-height: 24px;
}
.progress-md{
  height: 8px!important;
}
/* reports */
.nav-pills .nav-item{
  margin-right: 0;
  line-height: 1.5;
}
/* .nav-pills{
  border-bottom: none;
}
.nav-pills .nav-link{
border-radius: 0;
} */
.tab-content{
  background: none;
    /* border: none; */
    padding: 0;
}
.nav-pills-info .nav-link {
  color: rgb(187, 187, 187);
}
.nav-pills .nav-link {
  /* border: 1px solid #56597c; */
  padding: .2rem 1.75rem;
}
.report-page .datepicker .form-control,
.report-page select.form-control{
  height: 38px !important;
}
.input-group-append .input-group-text{
  padding: 0.475rem 0.75rem;
}
.datepicker.input-group{
  border: none;
}
.report-page .bulleted .plain-select{
  width: 95%;
}
.nav-tabs{
  background: #222840;
    border: none;
    margin-top: -1px;
}
.card{
  border:none !important;
}
.app-type{
  float: right;
  margin-top: -65px;
}
i.mdi.mdi-file-excel,
i.mdi.mdi-delete{
  font-size: 18px;
}

.report-page .input-group-text {
  height: 38px!important;
}
.report-page .react-datepicker__input-container input {
 padding-left: 45px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #3da5f4!important;
}
.calender_ic {
  content: ;
}
.calender_ic {
  position: absolute;
  z-index: 10;
  /* pointer-events: none; */
  cursor: pointer;
}

.scroll-x {
  overflow-x: auto;
}
.drop_down {
  max-width: 208px;
  justify-content: flex-end;
  display: flex;
  margin-right: 4px!important;
}
.select-box .css-vj8t7z,
.select-box .css-2o5izw,
.select-box .css-162g8z5{
  border: 1px solid #b5b5b5!important;
  background-color:transparent;
  /* height: 47px; */
  padding-left: 5px;
}
/* 
.select-box .css-vj8t7z:hover,
.select-box .css-vj8t7z:focus,
 {
  border-color: #80bdff;
  background-color: #2b2e4c!important;
} */

.css-vj8t7z:hover,
.css-vj8t7z:focus,
.css-1sontr1:hover,
.css-1sontr1:focus {
  border-color: #80bdff;
  background-color: #2b2e4c;
}
.text-centre {
  text-align: center;
}
.report-page .mdi:before, .mdi-set {
  line-height: 42px!important
}
.default{ 
  cursor: default; 
}
.pagination_center {
  justify-content: left;
  margin-left: 1%;
  display: flex;
}
.del_btn {
  justify-content: flex-end;
  display: flex;
}
.reportNote{
  text-align: right;
  padding-top: 10px;
}
.btn_refresh {
  width: 38px;
  height: 38px;
  margin-left: 8px;
  float:right;
}
.btn_refresh i {
  position: relative;
  top: -8px;
}
.ph-img {
  margin-top: 79px;
}
.margin-0 {
  margin: 0!important;
}
.date_in {
  position: relative;
  top: 2px;
  font-size: 12px;
  color:#3da5f4;
}
.cursor-pointer {
  height: 49px;
}
.cursor-pointer td {
  padding:0 15px!important;
}
.cursor-pointer .text-left {
  padding:0 15px!important;
}
.no_cursor {
  pointer-events: none;
}
.cursor-pointer i {
  cursor: pointer!important;
}
.file_icon {
  color: #3da5f4!important;
}


.report-page .react-datepicker__close-icon::after {

  background: #56585e;
  color: #babbbf;
}